@import "variables";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
  margin: 0;
  padding: 0;
}
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h1,
h2 {
  font-weight: 900;
}
small {
  font-weight: 400;
  font-size: 12px;
}
html {
  -webkit-font-smoothing: antialiased;
}
html {
  font-size: 100%;
  font-family: "Lato", sans-serif;
}
strong,
b {
  font-weight: 700;
}
p {
  color: #758294;
  font-size: 15px;
  line-height: 24px;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: currentColor;
}
a:hover {
  text-decoration: underline;
}
a,
button {
  transition: all 0.2s;
  font: inherit;
}
a:hover,
button:hover {
  cursor: pointer;
}
input,
textarea,
button {
  outline: none;
  border: 0;
  background: transparent;
}
body {
  font-weight: 400;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
}
.clear {
  width: 100%;
  clear: both;
  display: block;
  height: 0px;
}
img {
  max-width: 100%;
  height: auto;
}
input,
textarea,
select {
  color: inherit;
  font-family: inherit;

  &::placeholder {
    color: currentColor;
    opacity: 0.85;
  }
}
table {
  width: 100%;
}
::selection {
  background: #ccc;
  color: #fff;
}