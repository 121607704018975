.terms {
    h1 {
        padding: 5.2vw 0;
        background-color: #124b87;
        color: white;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
    }

    .text {
        max-width: 1024px;
        margin: 32px auto;

        h2 {
            font-size: 24px;
            margin-bottom: 1rem;
            font-weight: 700;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
            line-height: 1.5;
        }

        address {
            background-color: #f7f7f7;
            padding: 20px;
            margin-bottom: 1rem;
        }

        ul, ol {
            padding-left: 40px;
            margin-bottom: 1rem;

            li {
                & + li {
                    margin-top: .5rem;
                }
            }
        }

        ul {
            padding-left: 40px;

            ol {
                padding-left: 40px;
            }
        }
    }
}
