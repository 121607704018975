@import "variables";
@import "mixins";

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  max-width: 200px;
}

.container {
  width: 1600px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding: 0 66px;
}

.text {
  &-left {
    text-align: left !important;
  }
  &-center {
    text-align: center !important;
  }
  &-right {
    text-align: right !important;
  }
}

.login-form {
  display: flex;
  flex-wrap: wrap;

  .mat-form-field-label {
    color: #fff;
  }

  .mat-input-element {
    // caret-color: $color-gold;
  }
}

.login {
  .form-control {
    &.mat-form-field-label {
      color: #fff !important;
    }

    .mat-form-field-label {
      color: #fff !important;
    }

    .mat-form-field-underline {
      background-color: #fff !important;
    }

    .mat-form-field-ripple {
      background-color: #fff !important;
    }

    .mat-checkbox-frame {
      border-color: #fff !important;
    }
  }
}

// login/ reset-password
.login {
  .logo {
    position: absolute;
    top: 80px;
    max-width: 200px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    filter: brightness(0) invert(1);
  }

  p {
    margin: 0 0 30px;
    color: #fff;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
    min-height: 100%;

    .left,
    .right {
      flex: 0 1 50%;
      min-height: 100vh;
      position: relative;
    }

    .left {
      @include gradient($color-primary, #2a93d4);
      padding: 150px 10% 50px;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .right {
      background: url("../assets/bg-login.svg") center center no-repeat;
      background-size: contain;
      min-height: 400px;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0 16px;
    }
  }

  &-form {
    .form-control {
      flex: 0 1 100%;
      input {
        color: #fff;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 0 1 100%;
      margin-bottom: 40px;
    }
  }

  &-title {
    margin-bottom: 66px;
    font-weight: 900;
    font-size: 56px;
  }

  .btn {
    flex: 0 1 100%;
    border-radius: 25px;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
}

.dev-yourway {
  position: absolute;
  bottom: -120px;
  right: 10%;
}

main.logged {
  padding-top: 56px;
}
// end login/ reset-password

// home
.home {
  &-box {
    flex: 0 1 31.33%;
    width: 31.33%;
    min-height: 120px;
    border-bottom: 4px solid $color-primary;
    margin-bottom: 20px;

    span {
      color: $color-primary;
      font-size: 24px;
    }

    &:nth-child(3n-1) {
      margin-left: 3%;
      margin-right: 3%;
    }
  }

  &-title {
    font-size: 32px;
    margin-bottom: 60px;
  }
}

// header
.header-user .mat-button-wrapper {
  display: inline-flex !important;
  align-items: center;
}

main.logged {
  padding-top: 56px;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rate-limit-reached {
  color: $color-primary;
  max-width: 360px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.user {
  display: inline-flex;
  align-items: center;
  flex-flow: row;

  &-avatar {
    min-height: 36px;
    min-width: 36px;
    position: relative;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-name {
    display: inline-block;
    margin-left: 16px;
  }
}

// page
.page {
  margin-top: 60px;
  padding-bottom: 100px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &__buttons {
      button {
        margin-left: 24px;
      }
      .mat-button-toggle-button {
        margin-left: 0px;
      }
    }
  }

  &-title {
    font-size: 32px;
    font-weight: 700;
  }

  &-wrapper {
    position: relative;
  }

  &-boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &-box {
    flex: 0 1 24%;
    width: 24%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 16px 24px;
    border-bottom: 2px solid $color-primary;

    &__value {
      font-size: 48px;
      font-weight: 700;
      color: $color-primary;
      margin-bottom: 30px;
    }

    &__label {
      font-size: 16px;
      font-weight: 400;
    }

    &.mat-button {
      padding: 16px 24px;
      border-bottom: 2px solid $color-primary;
      border-radius: 0px;
      line-height: 1;
      min-height: 140px;
      text-align: left;
    }
  }

  &-content {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    padding: 24px 40px;
  }

  &-table {
    position: relative;

    &__search {
      font-size: 14px;
      width: 100%;

      input {
        color: $text-color-primary !important;
      }
    }

    &-filters {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      justify-content: space-between;

      .page-form-control {
        flex: 1;
        & + .page-form-control {
          margin-left: 16px;
        }
      }
    }

    &-container {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      position: relative;
    }

    /* Column Widths */
    .mat-column-number,
    .mat-column-state {
      max-width: 64px;
    }

    .mat-column-created {
      max-width: 124px;
    }

    td:last-child,
    th:last-child {
      text-align: right;
    }

    &-image {
      height: 75px;
      width: 75px;
      object-fit: contain;
      display: block;
    }
  }

  &-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
      rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    padding: 23px 40px;
    border-bottom: 4px solid $color-primary;
    position: relative;

    &__title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 23px;
      color: $color-primary;
      flex: 0 1 100%;
      width: 100%;
      position: relative;
    }

    hr {
      flex: 0 1 100%;
      width: 100%;
      border: 0;
      border-bottom: 1px solid #dadada;
      margin: 30px 0;
    }

    &-loading {
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 100;

      &-shade {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.5);
        z-index: 99;
      }
    }

    &-control {
      flex: 0 1 100%;
      width: 100%;
      margin-bottom: 10px;

      &.half {
        flex: 0 1 49%;
        width: 49%;
      }

      &.third {
        flex: 0 1 32.33%;
        width: 32.33%;
      }

      &.two-third {
        flex: 0 1 65.66%;
        width: 65.66%;
      }

      &.quad {
        flex: 0 1 24%;
        width: 24%;
      }
    }

    .page-radio-group {
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      &-label {
        font-size: 14px;
        color: $color-primary;
      }
    }

    .page-radio-button {
      margin: 5px;
    }

    &-image {
      position: relative;
      img {
        width: 240px;
        display: block;
        margin-bottom: 10px;
      }

      &__button {
        position: absolute;
        left: 8px;
        top: 8px;
        background: $danger;
        height: 32px;
        width: 32px;
        padding: 0;
        margin: 0;
        border-radius: 50%;
        color: #fff;
      }
    }

    .checkbox {
      &-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 16px;
      }

      &-item {
        display: inline-block;

        & + .checkbox-item {
          margin-left: 16px;
        }
      }
    }
  }
}

.btn-excel.mat-raised-button {
  background-color: #428f6a;
  color: #fff;

  .fas {
    margin-right: 8px;
  }
}

.btn-icon-excel {
  .fas {
    color: #428f6a;
  }
}
